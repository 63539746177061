// returns quote
// product.settings
const currency_fixed = {
    'HUF': 0.1,
    'BGN': 10.0,
    'RON': 1.0,
    'CZK': 1.0,
}
function currencyRound(value, currency) {
    let div = 100
    if (currency_fixed[currency]) {
        div = currency_fixed[currency]
    }
    value = Math.round(value * div) / div
    return value
}

const free_shipping_from = 28000.0 // HUF
const free_shipping_country = {
    'HU': true,
    'RO': true,
    'BG': true,
    'CZ': true,
    'SK': true,
}
const shipping_hu = 990 // 1490.0 // HUF
const shipping_hu_hd = 500 // 500.0 // +HUF
const shipping_hu_cod = 500 // 500.0 // +HUF
const shipping_hu_extra = 1500 // 1000 +HUF
const shipping_hu_mpl_extra = 0

const shipping_ro = 13.0 // RON
const shipping_ro_hd = 7.0 // +RON
const shipping_ro_cod = 7.0 // +RON
const shipping_ro_extra = 18.0 // +RON

const shipping_bg = 5.0 // BGN
const shipping_bg_hd = 3.0 // +BGN
const shipping_bg_cod = 3.0 // +BGN
const shipping_bg_extra = 8.0 // +BGN (GLS, DE NINCS!)

const shipping_sk = 5. // EUR
const shipping_sk_hd = 4. // EUR
const shipping_sk_cod = 2.0 // +EUR
const shipping_sk_extra = 10.0 // +EUR (GLS)

const shipping_cz = 110.0 // CZK
const shipping_cz_hd = 85.0 // +CZK
const shipping_cz_cod = 30.0 // +CZK
const shipping_cz_extra = 200.0 // +CZK (GLS)

const shipping_world = 20.0 // EUR
const shipping_europe = 15.0 // EUR
const shipping_gls = 13.0 // EUR

const businesscard_huf = {
    60 : 4950,
    100 : 6950,
    150 : 8250,
    200 : 9990,
    250 : 10900,
    300 : 12900,
    400 : 14900,
    500 : 17500,
    600 : 18500,
    700 : 19500,
    800 : 21500,
    900 : 23900,
    1000 : 25900,
    1100 : 27900,
    1200 : 28900
}

const businesscard_ron = {
    60 : 59,
    100 : 80,
    150 : 95,
    200 : 99,
    250 : 125,
    300 : 149,
    400 : 169,
    500 : 199,
    600 : 215,
    700 : 195,
    800 : 225,
    900 : 275,
    1000 : 299,
    1100 : 325,
    1200 : 335
}

const businesscard_eur = {
    60 : 30,
    100 : 33,
    150 : 38,
    200 : 43,
    250 : 49,
    300 : 53,
    400 : 56,
    500 : 61,
    600 : 70,
    700 : 74,
    800 : 84,
    900 : 91,
    1000 : 95,
    1100 : 100,
    1200 : 105,
}

const businesscard_coated_huf = 10
const businesscard_coated_eur = 0.03
const businesscard_cornered_huf = 10
const businesscard_cornered_eur = 0.03
const businesscard_twosided = 1.6

const rug_cornered_huf = 150
const rug_min_huf = 900
const rug_huf = 3.5

const calendar_huf = {
    'A3' :   {	
		1 : 9700,
        2 : 9700,
        3 : 9700,
        4 : 9700,
        5 : 8700,
        6 : 8700,
        7 : 8700,
        8 : 8700,
        9 : 8700,
        10 : 8700,
    },
    'A4' :   {
        1 : 8400,
        2 : 8400,
        3 : 8400,
        4 : 8400,
        5 : 7400,
        6 : 7400,
        7 : 7400,
        8 : 7400,
        9 : 7400,
        10 : 7400,
    },
    'desktop' :  {
        1 : 6500,
        2 : 6500,
        3 : 6500,
        4 : 6500,
        5 : 5500,
        6 : 5500,
        7 : 5500,
        8 : 5500,
        9 : 5500,
        10 : 5500,
    },
}

const calendar_ron = {
    'A3' :   {	
		1 : 126,
        2 : 126,
        3 : 126,
        4 : 126,
        5 : 113,
        6 : 113,
        7 : 113,
        8 : 113,
        9 : 113,
        10 : 113,
    },
    'A4' :   {
        1 : 110,
        2 : 110,
        3 : 110,
        4 : 110,
        5 : 96,
        6 : 96,
        7 : 96,
        8 : 96,
        9 : 96,
        10 : 96,
    },
    'desktop' :  {
        1 : 85 ,
        2 : 85,
        3 : 85,
        4 : 85,
        5 : 72,
        6 : 72,
        7 : 72,
        8 : 72,
        9 : 72,
        10 : 72,
    },
}

//calendar prices
const calendar_eur = {
    'A3' : {
        1  : 26,
        2  : 26,
        3  : 26,
        4  : 26,
        5  : 23,
        6  : 23,
        7  : 23,
        8  : 23,
        9  : 23,
        10 : 23,
    },
    'A4' :  {
        1  : 22,
        2  : 22,
        3  : 22,
        4  : 22,
        5  : 19,
        6  : 19,
        7  : 19,
        8  : 19,
        9  : 19,
        10 : 19,
    },
    'desktop' :  {
        1  : 17,
        2  : 17,
        3  : 17,
        4  : 17,
        5  : 14,
        6  : 14,
        7  : 14,
        8  : 14,
        9  : 14,
        10 : 14,
    },
}

const photobook_prices_huf = {
    's10'  : 5300,
    's20'  : 6000,
    's30'  : 7000,
    's40'  : 7900,
    'h40'  : 11500, //9900,
    'h60'  : 14300, //12300,
    'h80'  : 16300, //14000,
    'h100' : 18300, //15800,
    'h120' : 20500, //17600,
}

const photobook_prices_ron = {
    's10'  : 69,
    's20'  : 78,
    's30'  : 91,
    's40'  : 103,
    'h40'  : 150, //129, 
    'h60'  : 186, //160,
    'h80'  : 211, //182,
    'h100' : 238, //205,
    'h120' : 266, //229,
}

const photoalbum_prices_huf = {
    '15x15_10': 9900,
    '15x15_20': 14900,
    '15x15_30': 19900,

    '29x29_10': 19900,
    '29x29_20': 24900,
    '29x29_30': 29900,
    '29x29_40': 34900,
}

const photoalbum_prices_ron = {
    '15x15_10': 127,
    '15x15_20': 191,
    '15x15_30': 256,

    '29x29_10': 256,
    '29x29_20': 320,
    '29x29_30': 384,
    '29x29_40': 449,
}

const photobook_format_mul = {
    '15x15'  : 1.0,
    '20x20'  : 1.0,
    '20x29'  : 1.45, //1.25 volt
    '29x29'  : 2.0,
}

const photobook_discount_pcs_huf = 1000.0 // HUF
const photobook_discount_pcs_ron = 13.0 // RON


const photo_huf = {
    // fotónyomtatás
    '9x13': 90,
    '10x15': 100,
    '15x15': 150,
    '13x18': 170,
    '15x21': 290,
    '21x21': 990,
    '21x30': 1990,
    '30x40': 2900,
    '30x30': 2200,

    // vászonkép (új árak)
    'c35x50': 29900,  // B3 méret (15000 + ÁFA + árrés)
    'c50x70': 39900,  // B2 méret (20500 + ÁFA + árrés)
    'c35x100': 41900, // 2B3 méret (21200 + ÁFA + árrés)
    'c50x100': 45900, // 2B3+ méret (23500 + ÁFA + árrés)
    'c70x100': 54900, // B1 méret (28600 + ÁFA + árrés)
    'c100x140': 84900, // B0 méret (44100 + ÁFA + árrés)
    'c35x35': 24900,  // egyedi méret, arányosítva
    'c50x50': 34900,  // egyedi méret, arányosítva
    'c70x70': 44900,  // egyedi méret, arányosítva
    'c100x100': 64900, // egyedi méret, arányosítva

    // poszter (új árak)
    '35x50': 8900,   // B3 méret (3700 + ÁFA + árrés)
    '40x50': 9900,   // közel B3 méret
    '40x60': 11900,  // köztes méret
    '50x70': 12900,  // B2 méret (5100 + ÁFA + árrés)
    '61x91': 16900,  // köztes méret
    '70x100': 19900, // B1 méret (7000 + ÁFA + árrés)
    '75x100': 20900, // köztes méret
    '100x140': 27900, // B0 méret (11500 + ÁFA + árrés)
}

// habkarton (új árak)
const foamboard_huf = {
    '35x50': 12900,  // B3 méret (6700 + ÁFA + árrés)
    '40x50': 13900,  // közel B3 méret
    '40x60': 14900,  // köztes méret
    '50x70': 16900,  // B2 méret (8700 + ÁFA + árrés)
    '61x91': 22900,  // köztes méret
    '70x100': 24900, // B1 méret (13000 + ÁFA + árrés)
    '75x100': 25900, // köztes méret
    '100x140': 42900, // B0 méret (22300 + ÁFA + árrés)
}

// PVC (új árak)
const pvc_huf = {
    '35x50': 13900,  // B3 méret (6900 + ÁFA + árrés)
    '40x50': 14900,  // közel B3 méret
    '40x60': 15900,  // köztes méret
    '50x70': 17900,  // B2 méret (9300 + ÁFA + árrés)
    '61x91': 23900,  // köztes méret
    '70x100': 26900, // B1 méret (14000 + ÁFA + árrés)
    '75x100': 27900, // köztes méret
    '100x140': 44900, // B0 méret (23800 + ÁFA + árrés)
}

function CountryStatus(country) {
    // https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Glossary:Country_codes

    // EU
    let is_eu = {
        BE:true,
        BG:true,
        CZ:true,
        DK:true,
        DE:true,
        EE:true,
        IE:true,
        GR:true,
        ES:true,
        FR:true,
        HR:true,
        IT:true,
        CY:true,
        LV:true,
        LT:true,
        LU:true,
        HU:true,
        MT:true,
        NL:true,
        AT:true,
        PL:true,
        PT:true,
        RO:true,
        SI:true,
        SK:true,
        FI:true,
        SE:true,
    }

    // Posta európa
    let is_europe = {
        // EFTA
        IS:true,
        LI:true,
        NO:true,
        CH:true,

        // UK
        GB:true,

        // EU candidate
        ME:true,
        MK:true,
        AL:true,
        RS:true,
        TR:true,

        //potential candidate
        BA:true,
        XK:true,

        // European Neighbourhood Policy (ENP)-East countries:
        AM:true,
        AZ:true,
        BY:true,
        MD:true,
        GE:true,
        UA:true,

        // Other european
        AD:true, // Andorra
        VA:true, // VAtikán
        MC:true, // Monaco
        SM:true, // SanMarino

        RU:true,
    }

    // https://gls-group.eu/HU/hu/rendszeres-csomagfeladas/hasznos-informacio
    let is_gls = {
        HU: true, // I. zóna
        SK: true,
        AT: true,
        CZ: true,
        SI: true,
        RO: true,
        HR: true,

        DE: true, // II. zóna
        PL: true,
        BE: true,
        NL: true,
        LU: true,
        BG: true,

        IT: true, // III. zóna
        DK: true,
        FR: true,
        IE: true,

        ES: true, // IV. zóna
        PT: true,
        SE: true,
        FI: true,
        EE: true,
        LV: true,
        LT: true,

        //MT: true, // Malta
        //MC: true, // Monaco
        // görög?
    }

    let status = {
        eu: is_eu[country] ? true : false,
        europe: is_europe[country] ? true : false,
        gls: is_gls[country] ? true : false,
    }
    return status
}

function DiscountQuote(data={}, quote={}) {
    quote.product_price_before = currencyRound(quote.product_price, quote.currency)
    quote.price_before = quote.product_price_before + quote.shipping_price
    if (data.settings.affiliate_discount) {
        quote.discount_percent = data.settings.affiliate_discount
        quote.discountcode = data.settings.affiliate
        quote.affiliate = data.settings.affiliate
        quote.affiliate_name = data.settings.affiliate_name
        if (quote.discount_percent > 0) {
            let mul = 1.0 - (quote.discount_percent / 100.0)
            quote.product_price *= mul
        }
    } else if (data.settings.discountcode) {
        quote.discountcode = data.settings.discountcode.code
        quote.discount_percent = parseFloat(data.settings.discountcode.percent)
        quote.discount_value = parseFloat(data.settings.discountcode.value)
        let value_remains = parseFloat(data.settings.discountcode.value_remains)
        if (value_remains > 0) {
            quote.discount_value = value_remains
        }
        if (quote.discount_percent > 0) {
            quote.product_price *= 1.0 - (quote.discount_percent / 100.0)
        }
        if (quote.discount_percent > 99) {
            quote.shipping_free = true
            console.log('shipping free (100%)')
        }
        if (quote.discount_value > 0) {
            quote.product_price -= quote.discount_value
            if (quote.product_price < 0) {
                quote.discount_value_remains = -quote.product_price
                quote.product_price = 0
            }
        }
    } else {
        quote.discountcode = false
        quote.discount_percent = 0.0
    }
    return quote
}

function PrintQuote(data={}, country='HU', currency='USD', rates={}) {
    let pcs = parseInt(data.settings.pcs)
    let product = data.settings.product
    let quote = {
        product: product,
        name: '',
        description: '',
        pcs: pcs,
        currency: currency,
        discountcode: false,
        discount_percent: 0.0,
        product_price: 0.0,
        shipping_price: 0.0,
        price: 0.0,
    }
    let usd_price
    if (product == 'businesscard') {
        let istwosided = data.settings.twosided
        quote.istwosided = istwosided
        let pcss = ''+pcs
        if (country == 'HU' || country == 'RO' || country == 'BG' || country == 'SK' || country == 'CZ') {
            if (country == 'HU' || country == 'SK' || country == 'CZ') {
                let huf_price = businesscard_huf[pcss]
                usd_price = huf_price / rates['HUF']
            } else {
                let ron_price = businesscard_ron[pcss]
                usd_price = ron_price / rates['RON']
            }
            if (data.settings.coated) {
                usd_price += businesscard_coated_huf * pcs / rates['HUF']
            }
            if (data.settings.cornered) {
                usd_price += businesscard_cornered_huf * pcs / rates['HUF']
            }
            quote.product_price = usd_price * rates[currency]
        } else {
            let eur_price = businesscard_eur[pcss]
            if (data.settings.coated) {
                eur_price += businesscard_coated_eur * pcs
            }
            if (data.settings.cornered) {
                eur_price += businesscard_cornered_eur * pcs
            }
            usd_price = eur_price / rates['EUR']
            quote.product_price = usd_price * rates[currency]
        }
        if (istwosided) {
            quote.product_price *= businesscard_twosided
        }
    } else if (product == 'calendar') {
        let pcss = ''+pcs
        if (pcs > 10) {
            pcss = 10
        }
        let fmt = data.settings.format
        if (country == 'HU' || country == 'SK' || country == 'CZ') {
            let huf_price = pcs * calendar_huf[fmt][pcss]
            usd_price = huf_price / rates['HUF']
        } else if (country == 'RO' || country == 'BG') {
            let ron_price = pcs * calendar_ron[fmt][pcss]
            usd_price = ron_price / rates['RON']
        } else {
            let eur_price = pcs * calendar_eur[fmt][pcss]
            usd_price = eur_price / rates['EUR']
        }
        quote.product_price = usd_price * rates[currency]
    } else if (product == 'photobook') {
        let pcss = ''+pcs
        if (pcs > 10) {
            pcss = 10
        }
        let fmt = data.settings.format
        let make = data.settings.make
        let cover = make == 'book' ? 'h' : 's'
        let pages = parseInt(data.settings.pages)
        if (country == 'RO' || country == 'BG') {
            let ron_price
            if (make == 'album') {
                ron_price = pcs * photoalbum_prices_ron[fmt+'_'+pages]
            } else {
                ron_price = pcs * photobook_prices_ron[cover+pages]
                ron_price *= photobook_format_mul[fmt]
            }
            
            if (pcs >= 5) {
                ron_price -= pcs * photobook_discount_pcs_ron
            }
            ron_price = Math.round(ron_price)
            usd_price = ron_price / rates['RON']
        } else {
            let huf_price
            if (make == 'album') {
                huf_price = pcs * photoalbum_prices_huf[fmt+'_'+pages]
            } else {
                huf_price = pcs * photobook_prices_huf[cover+pages]
                huf_price *= photobook_format_mul[fmt]    
            }
            if (pcs >= 5) {
                huf_price -= pcs * photobook_discount_pcs_huf
            }
            usd_price = huf_price / rates['HUF']
        }
        quote.product_price = usd_price * rates[currency]
    } else if (product == 'rug') {
        let huf_price
        let w = parseFloat(data.settings.width) / 10.
        let h = parseFloat(data.settings.height) / 10.
        let area = w * h
        huf_price = pcs * (rug_min_huf + area * rug_huf)
        if (data.settings.cornered) {
            huf_price += rug_cornered_huf * 4 * pcs
        }
        huf_price = 100. * Math.floor(huf_price / 100.)
        usd_price = huf_price / rates['HUF']
        quote.product_price = usd_price * rates[currency]
    } else if (product == 'photo') {
        let photos = data.photos
        let huf_price = 0
        let pcount = 0
        for (let i = 0; i < photos.length; i++) {
            let photo = photos[i]
            let mode = photo.mode
            let size = photo.size
            let pcs = photo.pcs
            pcount += pcs

            if (mode == 'foamboard') {
                huf_price += pcs * foamboard_huf[size]
            } else if (mode == 'pvc') {
                huf_price += pcs * pvc_huf[size]
            } else {
                huf_price += pcs * photo_huf[size]
            }
        }
        usd_price = huf_price / rates['HUF']
        quote.product_price = usd_price * rates[currency]
        quote.pcount = pcount
        quote.pcs = pcount
    }

    quote = DiscountQuote(data, quote)

    if (data.settings.zerovat) {
        quote.product_price = quote.product_price * 0.78740
        quote.vat = 0
    } else {
        quote.vat = 27
    }
    
    quote.product_price = currencyRound(quote.product_price, currency)
    quote.shipping_price = currencyRound(quote.shipping_price, currency)
    quote.price = quote.product_price + quote.shipping_price
    return quote
}


function ShippingCheck(cart={}, rates = false) {
    let items = cart.items
    if (!items) {
        items = []
        cart.items = items
    }
    if (!rates) {
        rates = cart._rates.rates
    }
    if (items.length == 0) {return cart}
    let counter = {
        businesscard: 0,
        photobook: 0,
        calendar: 0,
        photo: 0
    }
    let all_price = 0

    let country = cart.shipping_country
    let ptype = cart.payment_type
    let currency = cart.currency
    let scountry = CountryStatus(country)

    items.forEach(data => {
        let product = data.settings.product
        let pcs = parseInt(data.settings.pcs)
        counter[product] += pcs
        all_price += data.quote.price
    })
    let all_price_huf = all_price / rates[currency] * rates['HUF']

    let all_free_shipping = false
    if (all_price_huf > free_shipping_from) {
        all_free_shipping = true
    }
    if (free_shipping_country[country]) {
        if (counter.businesscard >= 200 || counter.calendar >= 2 || counter.photobook >=2) {
            all_free_shipping = true
        }
    }
    let i = 0
    for (let i = 0; i < items.length; i++) {
        let data = items[i]
        let quote = data.quote
        if (all_free_shipping || i > 0 || quote.shipping_free) {
            data.settings.shipping_free = true
        } else {
            data.settings.shipping_free = false
        }
        if (i > 0) {
            data.settings.shipping_free_extra = true
        } else {
            data.settings.shipping_free_extra = false
        }
        cart.items[i] = ShippingQuote(cart, data, rates)

    }
    return cart
}

function ShippingQuote(cart={}, data={}, rates = false) {
    if (!rates) {
        rates = cart._rates.rates
    }
    let country = cart.shipping_country
    let ptype = cart.payment_type
    let currency = cart.currency
    let scountry = CountryStatus(country)

    let quote = data.quote
    if (data.settings.shipping_free) {
        quote.shipping_price = 0
        
        if ((cart.courier == 'gls' || cart.courier == 'packeta_mpl') && !data.settings.shipping_free_extra) {
            
            if (country == 'HU') {
                //GLS +1500 Ft
                if (cart.courier == 'gls') {
                    quote.shipping_price = shipping_hu_extra / rates['HUF'] * rates[currency]
                }
                // packeta_mpl +0 Ft
                if (cart.courier == 'packeta_mpl') {
                    quote.shipping_price = shipping_hu_mpl_extra / rates['HUF'] * rates[currency]
                }
    
                if (ptype == 'cash' || ptype == 'cash_pos' || ptype == 'wiretransfer_office') {
                    quote.shipping_price = 0
                }
            } else if (country == 'RO') {
                quote.shipping_price = shipping_ro_extra / rates['RON'] * rates[currency]
            } else if (country == 'BG') {
                quote.shipping_price = shipping_bg_extra / rates['BGN'] * rates[currency]
            } else if (country == 'CZ') {
                quote.shipping_price = shipping_cz_extra / rates['CZK'] * rates[currency]
            } else if (country == 'SK') {
                quote.shipping_price = shipping_sk_extra / rates['EUR'] * rates[currency]
            }
        }
    } else {
        let shipping_usd_price = 0
        let hd = cart.courier == 'gls' || cart.courier == 'packeta' || cart.courier == 'packeta_mpl' || cart.courier == 'fan' || cart.courier == 'sameday' || cart.courier == 'posta'
        console.log('quote country:', country, ' courier:', cart.courier)
        if (country == 'HU') {
            shipping_usd_price = shipping_hu / rates['HUF']
            shipping_usd_price += (hd ? shipping_hu_hd : 0.) / rates['HUF']
            shipping_usd_price += (ptype == 'cod' ? shipping_hu_cod: 0.) / rates['HUF']
            // GLS +1000 Ft
            if (cart.courier == 'gls') {
                shipping_usd_price += shipping_hu_extra / rates['HUF']
            }
            // packeta_mpl +90 Ft
            if (cart.courier == 'packeta_mpl') {
                shipping_usd_price += shipping_hu_mpl_extra / rates['HUF']
                console.log('packeta_mpl!')
            }
            if (ptype == 'cash' || ptype == 'cash_pos' || ptype == 'wiretransfer_office') {
                shipping_usd_price = 0
            }
        } else if (country == 'RO') {
            shipping_usd_price = shipping_ro / rates['RON']
            shipping_usd_price += (hd ? shipping_ro_hd : 0.) / rates['RON']
            shipping_usd_price += (ptype == 'cod' ? shipping_ro_cod: 0.) / rates['RON']
            if (cart.courier == 'gls') {
                shipping_usd_price += shipping_ro_extra / rates['RON']
            }
        } else if (country == 'BG') {
            shipping_usd_price = shipping_bg / rates['BGN']
            shipping_usd_price += (hd ? shipping_bg_hd : 0.) / rates['BGN']
            shipping_usd_price += (ptype == 'cod' ? shipping_bg_cod: 0.) / rates['BGN']
            if (cart.courier == 'gls') {
                shipping_usd_price += shipping_bg_extra / rates['BGN']
            }
        } else if (country == 'CZ') {
            shipping_usd_price = shipping_cz / rates['CZK']
            shipping_usd_price += (hd ? shipping_cz_hd : 0.) / rates['CZK']
            shipping_usd_price += (ptype == 'cod' ? shipping_cz_cod: 0.) / rates['CZK']
            if (cart.courier == 'gls') {
                shipping_usd_price += shipping_cz_extra / rates['CZK']
            }
        } else if (country == 'SK') {
            shipping_usd_price = shipping_sk / rates['EUR']
            shipping_usd_price += (hd ? shipping_sk_hd : 0.) / rates['EUR']
            shipping_usd_price += (ptype == 'cod' ? shipping_sk_cod: 0.) / rates['EUR']
            if (cart.courier == 'gls') {
                shipping_usd_price += shipping_sk_extra / rates['EUR']
            }
        } else {
            let s_eurprice = shipping_world
            if (scountry.gls) {
                s_eurprice = shipping_gls
                data.settings.shipping_free = true
            } else if (scountry.europe) {
                s_eurprice = shipping_europe
            }
            shipping_usd_price = s_eurprice / rates['EUR']
        }
        quote.shipping_price = shipping_usd_price * rates[currency]
    }
    if (data.settings.zerovat) {
        quote.shipping_price = quote.shipping_price * 0.78740
        quote.vat = 0
    } else {
        quote.vat = 27
    }
    quote.product_price = currencyRound(quote.product_price, currency)
    quote.shipping_price = currencyRound(quote.shipping_price, currency)
    quote.price = quote.product_price + quote.shipping_price
    quote.price_before = quote.product_price_before + quote.shipping_price

    //console.log('QUOTE',quote)
    return data
}

export { currencyRound, CountryStatus, PrintQuote, ShippingCheck, ShippingQuote, free_shipping_country }
